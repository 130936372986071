import * as Urql from 'urql'
import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

import * as Herz from '@rushplay/herz'

const getSeoMetadata = `
  query SeoMetadata($url: String!) {
    translatedSeoMetadata(url: $url) {
      description
      title
      url
    }
  }
`
const getGame = `
  query GameInfo($id: ID!) {
    game(id: $id) {
      displayProvider
      id
      key
      provider
      title
    }
  }
`

function GameSeoMetadataFallback(props) {
  const [gameResponse] = Urql.useQuery({
    query: getGame,
    variables: { id: props.gameId },
  })
  const game = gameResponse.data?.game
  const provider = game?.displayProvider || game?.provider

  const fallbackGameTitle = `${game?.title} - ${game?.displayProvider ||
    game?.provider} - Casino.me`

  const fallbackGameDescription = `game-info.${Herz.Utils.Strings.toKebabCase(
    provider
  )}.${Herz.Utils.Strings.toKebabCase(game?.key)}.description`

  const translate = Herz.I18n.useTranslate(
    () => [
      'seo.start.title',
      fallbackGameDescription,
      fallbackGameDescription.replace(/<[^>]+>/g, ''),
    ],
    [fallbackGameDescription]
  )

  // Gets rid of html tags
  const metaDescriptionContent =
    translate(fallbackGameDescription) &&
    translate(fallbackGameDescription).replace(/<[^>]+>/g, '')

  if (gameResponse.fetching || gameResponse.error) {
    return (
      <Helmet>
        <title>{translate(`seo.start.title`)}</title>
        <meta name="title" content={translate(`seo.start.title`)} />
      </Helmet>
    )
  }

  return (
    <Helmet>
      <title>{fallbackGameTitle}</title>
      <meta name="title" content={fallbackGameTitle} />
      {metaDescriptionContent && (
        <meta name="description" content={metaDescriptionContent} />
      )}
    </Helmet>
  )
}

GameSeoMetadataFallback.propTypes = {
  gameId: PropTypes.string,
}

export function SeoMetadata() {
  const translate = Herz.I18n.useTranslate()
  const location = ReactRouter.useLocation()
  const match = ReactRouter.useRouteMatch({
    path: '/casino/games/:gameId/',
  })

  const [response] = Urql.useQuery({
    query: getSeoMetadata,
    variables: { url: location.pathname },
  })

  if (
    !response?.fetching &&
    match &&
    response?.data?.translatedSeoMetadata == null
  ) {
    return <GameSeoMetadataFallback gameId={match.params.gameId} />
  }

  if (
    response.fetching ||
    response.error ||
    response.data?.translatedSeoMetadata?.title === null
  ) {
    return (
      <Helmet>
        <title>{translate(`seo.start.title`)}</title>
        <meta name="title" content={translate(`seo.start.title`)} />
        <meta name="description" content={translate(`seo.start.desc`)} />
      </Helmet>
    )
  }

  return (
    <Helmet>
      <title>{response.data?.translatedSeoMetadata?.title}</title>
      <meta
        name="title"
        content={response.data?.translatedSeoMetadata?.title}
      />
      {response.data?.translatedSeoMetadata?.description && (
        <meta
          name="description"
          content={response.data.translatedSeoMetadata.description}
        />
      )}
    </Helmet>
  )
}

Herz.I18n.Loader.preload(['seo.start.title', 'seo.start.desc'], SeoMetadata)
