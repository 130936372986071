import { createTheme } from './create-theme'

const STATIC_COLORS = [
  { name: 'static-black', value: 'hsl(0, 0%, 0%)' },
  { name: 'static-white', value: 'hsl(0, 0%, 100%)' },
]

const BASE_COLORS = [
  { name: 'base-50', value: 'hsl(284, 90%, 1%)' },
  { name: 'base-75', value: 'hsl(284, 70%, 5%)' },
  { name: 'base-100', value: 'hsl(284, 62%, 7%)' },
  { name: 'base-200', value: 'hsl(284, 45%, 13%)' },
  { name: 'base-300', value: 'hsl(284, 45%, 18%)' },
  { name: 'base-400', value: 'hsl(284, 40%, 23%)' },
  { name: 'base-500', value: 'hsl(284, 35%, 30%)' },
  { name: 'base-600', value: 'hsl(284, 20%, 55%)' },
  { name: 'base-700', value: 'hsl(284, 10%, 60%)' },
  { name: 'base-800', value: 'hsl(284, 5%, 80%)' },
  { name: 'base-900', value: 'hsl(284, 1%, 98%)' },
  { name: 'base-1000', value: 'hsl(284, 1%, 100%)' },

  { name: 'negative-50', value: 'hsl(357, 100%, 25%)' },
  { name: 'negative-75', value: 'hsl(357, 95%, 32%)' },
  { name: 'negative-100', value: 'hsl(357, 90%, 40%)' },
  { name: 'negative-200', value: 'hsl(357, 90%, 50%)' },
  { name: 'negative-300', value: 'hsl(357, 88%, 57%)' },
  { name: 'negative-400', value: 'hsl(357, 88%, 61%)' },
  { name: 'negative-500', value: 'hsl(357, 88%, 65%)' },
  { name: 'negative-600', value: 'hsl(357, 88%, 73%)' },
  { name: 'negative-700', value: 'hsl(357, 88%, 80%)' },
  { name: 'negative-800', value: 'hsl(357, 85%, 85%)' },
  { name: 'negative-900', value: 'hsl(357, 80%, 90%)' },
  { name: 'negative-1000', value: 'hsl(357, 75%, 95%)' },

  { name: 'positive-50', value: 'hsl(166, 100%, 8%)' },
  { name: 'positive-75', value: 'hsl(166, 100%, 15%)' },
  { name: 'positive-100', value: 'hsl(166, 100%, 20%)' },
  { name: 'positive-200', value: 'hsl(166, 100%, 30%)' },
  { name: 'positive-300', value: 'hsl(166, 100%, 40%)' },
  { name: 'positive-400', value: 'hsl(166, 100%, 50%)' },
  { name: 'positive-500', value: 'hsl(166, 98%, 30%)' },
  { name: 'positive-600', value: 'hsl(166, 95%, 35%)' },
  { name: 'positive-700', value: 'hsl(166, 60%, 45%)' },
  { name: 'positive-800', value: 'hsl(166, 50%, 60%)' },
  { name: 'positive-900', value: 'hsl(166, 45%, 70%)' },
  { name: 'positive-1000', value: 'hsl(166, 35%, 90%)' },

  { name: 'warning-50', value: 'hsl(65, 100%, 20%)' },
  { name: 'warning-75', value: 'hsl(65, 100%, 30%)' },
  { name: 'warning-100', value: 'hsl(65, 100%, 40%)' },
  { name: 'warning-200', value: 'hsl(65, 100%, 55%)' },
  { name: 'warning-300', value: 'hsl(65, 100%, 65%)' },
  { name: 'warning-400', value: 'hsl(65, 96%, 73%)' },
  { name: 'warning-500', value: 'hsl(65, 96%, 75%)' },
  { name: 'warning-600', value: 'hsl(65, 90%, 75%)' },
  { name: 'warning-700', value: 'hsl(65, 90%, 80%)' },
  { name: 'warning-800', value: 'hsl(65, 85%, 80%)' },
  { name: 'warning-900', value: 'hsl(65, 80%, 85%)' },
  { name: 'warning-1000', value: 'hsl(65, 70%, 93%)' },

  { name: 'info-50', value: 'hsl(282, 100%, 15%)' },
  { name: 'info-75', value: 'hsl(282, 90%, 20%)' },
  { name: 'info-100', value: 'hsl(282, 90%, 30%)' },
  { name: 'info-200', value: 'hsl(282, 85%, 35%)' },
  { name: 'info-300', value: 'hsl(282, 80%, 40%)' },
  { name: 'info-400', value: 'hsl(282, 43%, 46%)' },
  { name: 'info-500', value: 'hsl(282, 60%, 55%)' },
  { name: 'info-600', value: 'hsl(282, 55%, 60%)' },
  { name: 'info-700', value: 'hsl(282, 55%, 65%)' },
  { name: 'info-800', value: 'hsl(282, 50%, 70%)' },
  { name: 'info-900', value: 'hsl(282, 45%, 80%)' },
  { name: 'info-1000', value: 'hsl(282, 35%, 90%)' },
]

// These colors are very specific. Can't be found in current palettes
const COMPONENT_COLORS = [
  {
    name: 'game-info-missing-image-gradient-start',
    value: 'hsl(235, 76%, 56%)',
  },
  { name: 'header-gradient-stop-3', value: 'hsl(245, 43%, 30%)' },
  { name: 'header-gradient-stop-4', value: 'hsl(231, 52%, 36%)' },
  { name: 'header-gradient-end', value: 'hsl(220, 73%, 51%)' },
  { name: 'page-gradient-start', value: 'hsl(235, 76%, 56%)' },
  { name: 'page-gradient-middle', value: 'hsl(262, 54%, 48%)' },
  { name: 'loyalty-progress-bar-gradiant-end', value: 'hsl(305, 57%, 45%)' },
  { name: 'loyalty-progress-bar-gradiant-start', value: 'hsl(42, 100%, 74%)' },
  { name: 'welcome-offer-gradient-start', value: 'rgba(90, 41, 111, 0.6)' },
  { name: 'welcome-offer-gradient-stop', value: 'rgba(90, 41, 111, 0)' },
  { name: 'moments-header-paragraph', value: 'hsl(42, 100%, 63%)' },
  { name: 'moments-item-image-background', value: 'hsl(284, 66%, 7%)' },
  { name: 'moments-banner-background', value: 'hsla(0, 0%, 0%, 0.7)' },
  { name: 'my-identity-warning', value: 'hsl(48, 89%, 50%)' },
  { name: 'search-provider-button', value: 'hsl(282, 43%, 18%)' },
  { name: 'claimed-promotion-badge', value: 'hsl(23, 6%, 29%, 0.4)' },
]

export default createTheme({
  breakpoints: [
    { name: 'sm', value: '800px' },
    { name: 'md', value: '1000px' },
    { name: 'lg', value: '1200px' },
    { name: 'xl', value: '1500px' },
  ],

  colors: [
    ...STATIC_COLORS,
    ...BASE_COLORS,
    ...COMPONENT_COLORS,

    // ALIASES
    { __type: 'alias', name: 'badge-background', value: 'warning-400' },
    { __type: 'alias', name: 'button-text-disabled', value: 'base-800' },
    { __type: 'alias', name: 'button-text', value: 'base-1000' },
    { __type: 'alias', name: 'primary-button-text-disabled', value: 'base-50' },
    { __type: 'alias', name: 'primary-button-text', value: 'base-50' },
    { __type: 'alias', name: 'card-background', value: 'base-75' },
    { __type: 'alias', name: 'checkbox-checked', value: 'info-400' },
    { __type: 'alias', name: 'card-text', value: 'static-white' },
    {
      __type: 'alias',
      name: 'cookie-banner-background',
      value: 'static-white',
    },
    { __type: 'alias', name: 'danger', value: 'negative-400' },
    { __type: 'alias', name: 'dashboard-edit-button', value: 'base-75' },
    { __type: 'alias', name: 'disabled', value: 'base-800' },
    { __type: 'alias', name: 'g-bg', value: 'base-100' },
    { __type: 'alias', name: 'g-text', value: 'base-900' },
    { __type: 'alias', name: 'empty-card-background', value: 'info-400' },
    {
      __type: 'alias',
      name: 'failed-loading-casino-background',
      value: 'base-100',
    },
    {
      __type: 'alias',
      name: 'featured-label-background',
      value: 'base-100',
    },
    {
      __type: 'alias',
      name: 'featured-label-text',
      value: 'base-900',
    },
    { __type: 'alias', name: 'footer-background', value: 'base-75' },
    { __type: 'alias', name: 'game-tile-background', value: 'base-75' },
    { __type: 'alias', name: 'header-gradient-start', value: 'base-100' },
    { __type: 'alias', name: 'header-gradient-stop-1', value: 'base-200' },
    { __type: 'alias', name: 'header-gradient-stop-2', value: 'base-300' },
    {
      __type: 'alias',
      name: 'heading-text',
      value: 'base-200',
    },
    {
      __type: 'alias',
      name: 'input-background',
      value: 'base-900',
    },
    {
      __type: 'alias',
      name: 'input-text',
      value: 'base-200',
    },
    {
      __type: 'alias',
      name: 'page-gradient-stop',
      value: 'base-75',
    },
    {
      __type: 'alias',
      name: 'loyalty-program-progressbar-track',
      value: 'base-300',
    },
    { __type: 'alias', name: 'menu-balance-background', value: 'base-100' },
    {
      __type: 'alias',
      name: 'menu-balance-visibility-toggler',
      value: 'base-300',
    },
    {
      __type: 'alias',
      name: 'menu-balance-visibility-toggler-hover',
      value: 'base-500',
    },
    { __type: 'alias', name: 'menu-loyalty-background', value: 'base-100' },
    { __type: 'alias', name: 'my-identity-background', value: 'base-300' },
    { __type: 'alias', name: 'my-identity-dimmed', value: 'base-700' },
    { __type: 'alias', name: 'my-profile-background', value: 'base-600' },
    { __type: 'alias', name: 'nav', value: 'base-200' },
    { __type: 'alias', name: 'nav-active', value: 'base-300' },
    { __type: 'alias', name: 'nav-badge', value: 'warning-400' },
    { __type: 'alias', name: 'nav-highlight', value: 'negative-400' },
    { __type: 'alias', name: 'nav-badge', value: 'warning-400' },
    { __type: 'alias', name: 'nav-hover', value: 'base-300' },
    { __type: 'alias', name: 'nav-item', value: 'info-400' },
    { __type: 'alias', name: 'offer-picker-info', value: 'info-400' },
    { __type: 'alias', name: 'page-nav-heading', value: 'base-900' },
    { __type: 'alias', name: 'picked-game', value: 'info-400' },
    { __type: 'alias', name: 'primary', value: 'positive-400' },
    { __type: 'alias', name: 'primary-active', value: 'positive-300' },
    { __type: 'alias', name: 'primary-hover', value: 'positive-600' },
    { __type: 'alias', name: 'radio-button-checked', value: 'info-400' },
    {
      __type: 'alias',
      name: 'registration-progress-background',
      value: 'info-400',
    },
    {
      __type: 'alias',
      name: 'registration-progress-active-background',
      value: 'info-400',
    },
    { __type: 'alias', name: 'secondary-button', value: 'info-400' },
    { __type: 'alias', name: 'secondary-button-hover', value: 'info-500' },
    { __type: 'alias', name: 'text-disabled', value: 'base-800' },
    { __type: 'alias', name: 'wallet-progress-background', value: 'base-75' },
    { __type: 'alias', name: 'big-win-background', value: 'base-300' },
    { __type: 'alias', name: 'unseen-inventory-border', value: 'info-400' },
    { __type: 'alias', name: 'unseen-promotion-border', value: 'info-400' },
    { __type: 'alias', name: 'welcome-offer-shadow', value: 'info-500' },
    { __type: 'alias', name: 'provider-button-selected', value: 'info-400' },
    { __type: 'alias', name: 'active-promotion-badge', value: 'info-400' },
    {
      __type: 'alias',
      name: 'claimed-promotion-badge-text',
      value: 'static-black',
    },

    // VARIANTS
    //  - ALTERNATIVE
    {
      __type: 'alias',
      name: 'g-bg',
      value: 'base-900',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'g-text',
      value: 'base-75',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'heading-text',
      value: 'base-75',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'input-background',
      value: 'base-75',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'input-text',
      value: 'base-900',
      variant: 'alternative',
    },
    {
      __type: 'alias',
      name: 'my-account-body-text',
      value: 'base-75',
      variant: 'alternative',
    },

    // LEGACY
    { name: 'cardBackground', value: '#ffffff' },
    { name: 'gameTileFeatured', value: '#371b43' },
    { name: 'gameTileHighestWin', value: '#009f66' },
    { name: 'gameTileNewest', value: '#1fb6be' },
    { name: 'gameTileNewestProvider', value: '#fdac00' },
    { name: 'gameTileNewestTable', value: '#3c3abb' },
    { name: 'gameTilePopular', value: '#fa5847' },
    { name: 'conditionalGameTileText', value: '#ffffff' },
    { name: 'htmlContent', value: '#43484F' },
    { name: 'info', value: '#1fb6be' },
    { name: 'input', value: '#c9c9c9' },
    { name: 'inputBackground', value: '#ffffff' },
    { name: 'inputDisabled', value: '#3A2B42' },
    { name: 'inputHover', value: '#a9a9a9' },
    { name: 'inputText', value: '#43484F' },
    { name: 'kewlLinkIconBackground', value: '#ffffff' },
    { name: 'kewlLinkIconColor', value: '#17061d' },
    { name: 'scrollBarThumb', value: '#0D989F' },
    { name: 'scrollBarTrack', value: '#371b43' },
    { name: 'secondary', value: '#13838C' },
    { name: 'secondaryActive', value: '#0D989F' },
    { name: 'secondaryHover', value: '#28cad3' },
    { name: 'selectText', value: '#43484F' },
    { name: 'success', value: '#009f66' },
    { name: 'textDanger', value: '#fa5847' },
    { name: 'warning', value: '#ffc956' },
  ],

  fonts: [
    {
      name: 'head',
      value:
        "'M PLUS 1p', 'Hiragino Sans', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif",
    },
    {
      name: 'body',
      value:
        "'Hiragino Sans', 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'Yu Gothic', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif",
    },
  ],

  fontSizes: [
    { value: 10 },
    { value: 12 },
    { value: 14 },
    { value: 16 },
    { value: 18 },
    { value: 20 },
    { value: 24 },
    { value: 32 },
    { value: 48 },
  ],

  radii: [{ value: 4 }, { value: 8 }],

  space: [
    { value: 8 },
    { value: 16 },
    { value: 24 },
    { value: 32 },
    { value: 40 },
    { value: 64 },
    { value: 80 },
    { value: 112 },
    { value: 160 },
  ],

  zIndices: [
    { name: 'hide', value: -1 },
    { name: 'base', value: 0 },
    { name: 'docked', value: 1 },
    { name: 'small', value: 10 },
    { name: 'medium', value: 100 },
    { name: 'high', value: 110 },
    { name: 'highest', value: 999 },
  ],

  shadows: [
    { value: '0 3px 4px 0 rgba(0, 0, 0, 0.1)' },
    { value: '0 7px 14px 0 rgba(0, 0, 0, 0.1)' },
    { value: '0 7px 40px 0 rgba(0, 0, 0, 0.1)' },
  ],

  variants: [
    {
      name: 'bg-pattern-default',
      value: '/images/background-pattern-stripe.svg',
    },
    {
      name: 'bg-pattern-d-shape',
      value: '/images/background-pattern-d-shape.svg',
    },
    {
      name: 'bg-pattern-stripe-small',
      value: '/images/background-pattern-stripe-small.svg',
    },
    {
      name: 'bg-pattern-stripe-large',
      value: '/images/background-pattern-stripe.svg',
    },
    {
      name: 'buttons',
      value: {
        primary: {
          borderRadius: '9999px',
          color: 'primary-button-text',
          bg: 'primary',
          border: 'none',
          '&:hover:enabled, &:focus': {
            bg: 'primary-hover',
            boxShadow: 1,
          },
          '&:active:enabled': {
            bg: 'primary-active',
            boxShadow: 0,
          },
          '&:disabled': {
            bg: 'disabled',
            cursor: 'not-allowed',
            opacity: 0.5,
          },
        },
        secondary: {
          borderRadius: '9999px',
          border: 'none',
          color: 'button-text',
          bg: 'secondary-button',
          '&:hover:enabled, &:focus': {
            bg: 'secondary-button-hover',
            boxShadow: 1,
          },
          '&:active:enabled': {
            bg: 'secondary-button',
            boxShadow: 0,
          },
          '&:disabled': {
            bg: 'disabled',
            cursor: 'not-allowed',
            opacity: 0.5,
          },
        },
        'primary-outlined': {
          borderRadius: '9999px',
          color: 'primary',
          borderColor: 'primary',
          border: '2px solid currentColor',
          bg: 'transparent',
          padding: '0.375em 1.375em',
          '&:hover:enabled, &:focus': {
            color: 'primary-hover',
          },
          '&:active:enabled': {
            color: 'primary-active',
          },
          '&:disabled': {
            bg: 'transparent',
            borderColor: 'disabled',
            color: 'primary-button-text-disabled',
            cursor: 'not-allowed',
            opacity: 0.5,
          },
        },
        'secondary-outlined': {
          borderRadius: '9999px',
          color: 'secondary-button',
          border: '2px solid currentColor',
          bg: 'transparent',
          padding: '0.375em 1.375em',
          '&:hover:enabled, &:focus': {
            color: 'secondary-button-hover',
          },
          '&:active:enabled': {
            color: 'secondary-button',
          },
          '&:disabled': {
            bg: 'transparent',
            borderColor: 'disabled',
            color: 'button-text-disabled',
            cursor: 'not-allowed',
            opacity: 0.5,
          },
        },
      },
    },
    {
      name: 'radio-buttons',
      value: {
        radio: {
          position: 'relative',
          '& > label::before': {
            content: "''",
            display: 'inline-block',
            width: '2em',
            height: '2em',
            marginRight: 0,
            padding: '0.2em',
            backgroundColor: 'transparent',
            backgroundClip: 'content-box',
            borderRadius: '9999px',
            borderColor: 'text-disabled',
            borderStyle: 'solid',
            borderWidth: '2px',
            verticalAlign: '-.35em',
            transitionDuration: '300ms',
            transitionProperty: 'all',
          },
          '& > label > *': {
            marginRight: 0,
          },
          'input[type="radio"]:checked + label::before': {
            backgroundColor: 'radio-button-checked',
            borderColor: 'radio-button-checked',
            transitionDuration: '100ms',
          },
        },
      },
    },
  ],
})
