import * as R from 'ramda'

const EMPTY_ARRAY = []

export const UPDATED = 'casinome/picked-games/UPDATED'
export const SET = 'casinome/picked-games/SET'

export const UPDATE_PICKED_GAMES_PROCESS =
  'casinome/picked-games/update-picked-games-process'

export function getPickedGames(state) {
  return R.path(['games'], state) || EMPTY_ARRAY
}

export function getSelectedGameIndex(state) {
  return R.path(['gameIndex'], state)
}

export function getGamesIds(state) {
  return R.map(
    game =>
      R.equals(game.type, 'fixed-game')
        ? R.path(['game', 'id'], game)
        : game.type,
    R.pathOr(EMPTY_ARRAY, ['games'], state)
  )
}

export function isAllGamesUnset(state) {
  return R.all(
    R.isNil,
    R.reduce(
      (games, game) => {
        if (game.type !== 'empty' && R.isNil(game.game)) {
          return R.append(true, games)
        }
        return R.append(game.game, games)
      },
      [],
      R.path(['games'], state) || EMPTY_ARRAY
    )
  )
}

/**
 * @param {Array.<number>} games
 */
export function updatePickedGames(games) {
  return {
    type: UPDATED,
    error: games instanceof Error,
    payload: games,
  }
}

/**
 * @param {number} gameIndex
 */
export function setSelectedGameIndex(gameIndex) {
  return {
    type: SET,
    error: gameIndex instanceof Error,
    payload: gameIndex,
  }
}

export function reducer(state = {}, action) {
  if (R.path(['error'], action)) {
    return state
  }

  switch (action.type) {
    case UPDATED: {
      return R.assoc(['games'], action.payload, state)
    }

    case SET: {
      return R.assoc(['gameIndex'], action.payload, state)
    }

    default: {
      return state
    }
  }
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {string} props.id
 * @returns {Object} game
 */
export function getGame(state, props) {
  return R.path(
    ['game'],
    R.find(R.pathEq(['game', 'id'], props.id))(
      R.pathOr(EMPTY_ARRAY, ['games'], state)
    )
  )
}
