import * as React from 'react'
import * as ReactRedux from 'react-redux'
import { Helmet } from 'react-helmet-async'

import * as Herz from '@rushplay/herz'

import * as Configuration from './configuration'

export function SeoJsonLd() {
  const translate = Herz.I18n.useTranslate()

  const origin = ReactRedux.useSelector(state =>
    Configuration.getOrigin(state.configuration)
  )

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://www.schema.org',
          '@type': 'Organization',
          name: 'Casino.me',
          legalName: 'Casino.me',
          alternateName: 'casino me',
          description: translate(`seo.casinome.start.desc`),
          logo: `${origin}/images/logo.svg`,
          url: origin,
          foundingDate: '2020',
        })}
      </script>
    </Helmet>
  )
}

Herz.I18n.Loader.preload(['seo.casinome.start.desc'], SeoJsonLd)
